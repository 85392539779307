jQuery(document).ready(function ($) {


  /*
   Navbar
   */
  var $window = $(window);
  var $navbarLinks = $('.navbar-nav li a');
  var actUrl = window.location.pathname;

  var equalHeightForNavLinks = function () {
    $navbarLinks.css('height', 'auto');
    var maxHeight = 0;
    $navbarLinks.each(function (index, elem) {
      var height = parseInt($(elem).css('height'), 10);
      if (height > maxHeight) {
        maxHeight = height;
      }
    });
    $navbarLinks.css('height', maxHeight);
  };


  $window.load(function () {
    $navbarLinks.fadeTo(100, 1);
  });

  var timerId;
  $window.resize(function () {

    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(function () {
      if (Modernizr.mq('only screen and (min-width: 768px)')) {
        equalHeightForNavLinks();
      } else {
        $navbarLinks.css('height', 'auto');
      }
    }, 100);
  });

  if (Modernizr.mq('only screen and (min-width: 768px)')) {

    equalHeightForNavLinks();

  }


  /*
   Accordion
   */

  var $panels = $('#accordion-1').add('#accordion-2').find('.panel');
  var $panelLinks = $panels.find('.panel-heading a');

  $panelLinks.each(function (index, link) {

    var $link = $(link);

    $link.click(function () {
      var $linkParents = $link.parents('.panel');
      if (!$linkParents.find('.panel-collapse').hasClass('in')) {
        $panels.removeClass('active');
      }
      $linkParents.toggleClass('active');
    });

  });




  var startRoyalSlider = function(){

    var $galleryText = $('.gallery-text');
    var galleryText = [];

    var setGalleryText = function () {

      var $rsThumbsContainer = $('.rsThumbsContainer');
      var selectedIndex = $rsThumbsContainer.find('.rsThumb').index($rsThumbsContainer.find('.rsNavSelected'));
      var html = '<li class="new"><h4 class="title">' + galleryText[selectedIndex].title + '</h4><p class="caption">' + galleryText[selectedIndex].caption + '</p></li>';
      var $li = $galleryText.find('li');

      if ($li.length === 1) {
        $galleryText.append(html);
        var $newLi = $galleryText.find('.new');
        var $activeLi = $galleryText.find('.active');

        $activeLi.remove();
        $newLi.addClass('active').fadeIn(250);

      } else {
        $galleryText.html(html);
        $galleryText.find('li').addClass('active').fadeIn();
      }
    };

    if (Modernizr.mq('only screen and (min-width: 768px)')) {

      $(".royalSlider").royalSlider({
        controlNavigation: 'thumbnails',
        keyboardNavEnabled: true,
        controlsInside: false,
        usePreloader: true,
        thumbs: {
          // thumbnails options go gere
          orientation: 'vertical',
          spacing: 10,
          transitionType: 'fade',
          arrowsAutoHide: true
        }
      });

      var slider = $(".royalSlider").data('royalSlider');
      slider.ev.on('rsAfterSlideChange', function (event) {
        setGalleryText();
      });


      // read titles and captions
      $galleryText.find('li').each(function (index, elem) {
        var $elem = $(elem);
        galleryText.push({
          title: $elem.find('.title').text(),
          caption: $elem.find('.caption').text()
        });
      });
      $galleryText.empty();


      // prepend thumbnails to #gallery-meta
      var timerId;
      slider.ev.on('rsAfterContentSet', function(e, slideObject) {
        // fires when every time when slide content is loaded and added to DOM
        if (timerId) {
          clearInterval(timerId);
        }
        timerId = setTimeout(function(){

          $('.rsThumbsVer').prependTo('#gallery-meta');

        }, 100);
      });

      setGalleryText();

      $(window).load(function(){
        setGalleryText();
      });

      return 'desktop';

    } else {

      $(".royalSlider").royalSlider({
        controlNavigation: 'none',
        keyboardNavEnabled: true,
        arrowsNav: false
      });

      return 'mobile';

    }

  };

  if (actUrl.indexOf('malerei') > -1) {

    var mode = startRoyalSlider();


    $(window).resize(function(){

      var timerId;
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(function(){

        if (Modernizr.mq('only screen and (min-width: 768px)') && mode === 'mobile'
          || !Modernizr.mq('only screen and (min-width: 768px)') && mode === 'desktop') {
            window.location = window.location;
        }

      }, 100);

    });

  }

  $('#drlgt-slider-1').drlgtSlider();


});