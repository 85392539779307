$.fn.drlgtSlider = function (options) {
  var defaults = {
    speed: 800,
    pause: 7000,
    transition: 'fade'
  };
  options = $.extend(defaults, options);


  var $this = this;
  /**
   * preloads a specified amount of images
   * @param  {array} arr
   * @return {object}
   */
  function preloadImages(imgData, preloadImagesCount) {
    var preloadedImgs = [];
    var loadedimages = 0;
    var postaction = function () {};

    imgData = imgData.constructor === Array ? imgData : [imgData];
    preloadImagesCount = preloadImagesCount || imgData.length;

    function imageloadpost() {
      loadedimages++;
      if (loadedimages == preloadImagesCount) {
        postaction(preloadedImgs); //call postaction and pass in preloadArr imgDataay as parameter
      }
    }

    for (var i = 0, len = imgData.length; i < len; i++) {
      preloadedImgs[i] = new Image();
      preloadedImgs[i].src = imgData[i].src;
      preloadedImgs[i].alt = imgData[i].caption;
      if (i < preloadImagesCount) {
        preloadedImgs[i].onload = function () {
          imageloadpost();
        };
        preloadedImgs[i].onerror = function () {
          console.log("error");
        };
      }
    }
    return { //return blank object with done() method
      done: function (f) {
        postaction = f || postaction; //remember user defined callback functions to be called when images load
      }
    };
  }


  function fade(direction, slider) {
    var
      $nextSlide,
      $activeSlide = slider.find('.active'),
      $sliderLi = slider.find('li');

    if (direction == 'left') {
      $nextSlide = ($activeSlide.prev('.slide').length ? $activeSlide.prev('.slide') : slider.find('.slide').eq($sliderLi.length - 1));
    } else {
      $nextSlide = ($activeSlide.next('.slide').length ? $activeSlide.next('.slide') : slider.find('.slide').eq(0));
    }
    $nextSlide.addClass('next');

    $activeSlide
      //.velocity('stop')
      .velocity({
        opacity: 0
      }, {
        duration: options.speed,
        complete: function () {
          $activeSlide.css({opacity: 1}).removeClass('active');
          $nextSlide.addClass('active').removeClass('next');
        }
      });

  }

  /**
   * for each .slider element do
   */
  this.each(function () {
    var
      $this = $(this),
      imgData = [],
      initSlide;
    $this.find("a.image").each(function () {
      imgData.push({
        src: $(this).attr("href"),
        caption: $(this).text()
      });
    });
    $this.find("a.image").remove();

    preloadImages(imgData, 1)
      .done(function (preloadedImgs) {

        $this.velocity({
          opacity: 1
        });
        $this.find('li').addClass('slide').each(function (index, elem) {
          $(elem).css('background-image', 'url(' + preloadedImgs[index].src + ')');
        });

        $this.children('li').first().addClass('active');

        initSlide = setInterval(function () {
          fade('right', $this);
        }, options.pause);

      });
  });

};